/*-----------------------------------------------------------------------------------

    Template Name: Boost - A Bold, Modern Impactful HTML5 Template
    Template URI: https://luminousdemo.com/dt-template-html/boost
    Description: This Is Ecommerce HTML5 Template.
    Author: LuminousLabs
    Author URI:  https://themeforest.net/user/luminouslabs
    Version: 1.0.0

----------------------------------------------------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700;800;900&display=swap");

/*---------------------------------------------------------------------------- 
    Reset
----------------------------------------------------------------------------*/
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*::after,
*::before {
  box-sizing: border-box;
}

html {
  padding-bottom: 0 !important;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: "Barlow", sans-serif !important;
}

body {
  background-color: #f1f1f1 !important;
  margin: 0 auto;
  font-size: 16px;
  color: #1d1d1d;
  font-family: "Barlow", sans-serif !important;
  overflow-x: hidden;
}

:root {
  --primarty-color: #2963ff;
  --secondary-color: #50248f;

  --primary-font: "Barlow", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--primary-font) !important;
}

*:focus {
  outline: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

ol {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--primarty-color);
  display: inline-block;
  transition: color 150ms;
  -o-transition: color 150ms;
  -webkit-transition: color 150ms;
  -moz-transition: color 150ms;
  -ms-transition: color 150ms;
}

a:hover,
a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  color: #1c5352;
}

img {
  width: 100%;
  height: auto;
  border: none;
}

svg {
  fill: currentColor;
  stroke: currentColor;
  width: 20px;
  height: 20px;
}

.text-strike {
  text-decoration: line-through;
}

button {
  line-height: 1.4em;
}

button:focus {
  outline: none;
}

.container {
  max-width: 100%;
}

.primary-btn {
  font-size: 16px;
  letter-spacing: inherit;
  border: 1px solid var(--secondary-color);
  background-color: var(--secondary-color);
  color: #ffffff;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  text-transform: capitalize;
  box-shadow: 0 0 0 1px var(--secondary-color);
}

.primary-btn:hover {
  color: #ffffff;
  filter: contrast(90%) brightness(1.1);
  box-shadow: 0 0 0 1px var(--secondary-color),
    0 4px 10px -4px var(--secondary-color), 0 2px 6px 0px rgb(0 0 0 / 50%);
  transform: translateY(-3px);
}

.secondary-btn {
  font-size: 16px;
  letter-spacing: inherit;
  border: 1px solid var(--secondary-color);
  background-color: #ffffff;
  color: #230051;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 100ms ease-in-out;
  -moz-transition: all 100ms ease-in-out;
  -ms-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  text-transform: capitalize;
  box-shadow: 0 0 0 1px var(--secondary-color);
}

.secondary-btn:hover {
  color: #230051;
  background-color: #ffffff;
  --button-border-color: rgba(35, 0, 81, 0.25);
  filter: contrast(90%) brightness(1.1);
  box-shadow: 0 0 0 1px var(--secondary-color),
    0 4px 10px -4px var(--secondary-color), 0 2px 6px 0px rgb(0 0 0 / 50%);
  transform: translateY(-3px);
}

/*---------------------------------------------------------------------------- 
    Header
----------------------------------------------------------------------------*/
/* Top Header CSS */
.header-top {
  padding: 15px 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.site-logo {
  height: 35px;
}

.icons img {
  height: 45px;
  width: 45px;
  margin-right: 10px;
  cursor: pointer;
}

.icons svg {
  font-size: 50px;
  line-height: 1;
  vertical-align: middle;
}

.icons .icon-single {
  height: 45px;
  width: 45px;
  line-height: 42px;
  text-align: center;
  background-color: #eaefff;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.icons .icon-single:hover {
  background-color: var(--primarty-color);
}

.icons .icon-single svg {
  font-size: 20px;
  line-height: 1;
  vertical-align: middle;
  color: var(--primarty-color);
  transition: all 0.3s ease-in;
}

.icons .icon-single:hover svg {
  color: #ffffff;
}

.user-info {
  cursor: pointer;
}

.user-info img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 50%;
}

.info p {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}

.info span {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.info {
  position: relative;
  margin-left: 10px;
}

.info img {
  width: 15px;
  height: auto;
  cursor: pointer;
  position: relative;
  bottom: 0;
  margin-left: 15px;
}

.info svg {
  font-size: 18px;
  cursor: pointer;
  position: relative;
  bottom: 15px;
  margin-left: 15px;
  color: var(--primarty-color);
  line-height: 1;
  vertical-align: middle;
}

.userMenu {
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  top: 60px;
  left: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  box-shadow: 0 2px 3px 0 #ddd;
}

.userMenu.show {
  border: 1px solid #e5e6e7;
  top: 55px;
  opacity: 1;
  visibility: visible;
}

.userMenu li {
  color: #111111;
  font-size: 17px;
  font-weight: 500;
  line-height: 1;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #e5e6e7;
  transition: all 0.3s ease-in;
}

.userMenu li:hover {
  color: #2963ff;
}

.userMenu li:last-child {
  border-bottom: 0;
}

.top-right.buttons a p {
  font-size: 16px;
  padding: 15px 30px;
  margin-left: 15px;
  border-radius: 10px;
  transition: all 0.3s ease-in;
  background-color: #eaefff;
  color: var(--primarty-color);
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}

.top-right.buttons a p:hover {
  background-color: var(--primarty-color);
  color: #ffffff;
}

/* Header Bottom CSS */
.header-bottom {
  height: 80px;
}

.search-form input {
  width: 320px;
  height: 45px;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #dadbdc;
  background-color: #ffffff;
}

.search-form input::placeholder {
  color: #9fa5ad;
}

.single-icon {
  height: 50px;
  line-height: 50px;
  width: 50px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  text-align: center;
  margin-left: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.single-icon:hover,
.single-icon.focus {
  background-color: #eaefff;
  border-color: rgba(41, 98, 255, 0.1);
}

.single-icon img {
  width: 25px;
  display: inline-block;
}

.single-icon svg {
  color: #727272;
  font-size: 30px;
  line-height: 1;
  vertical-align: middle;
}

.header-bottom-right button {
  font-size: 16px;
  padding: 13px 60px;
  margin-left: 30px;
  border-radius: 10px;
  background-color: var(--primarty-color);
  border: 1px solid var(--primarty-color);
  color: #ffffff;
  transition: all 0.3s ease-in;
}

.header-bottom-right button:hover {
  background-color: #eaefff;
  color: var(--primarty-color);
}

/*---------------------------------------------------------------------------- 
    Navbar
----------------------------------------------------------------------------*/
.main-nav {
  height: 65px;
  line-height: 65px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

.nav-middle ul {
  margin-bottom: 0;
}

.nav-middle ul li {
  margin: 0 10px;
  color: #9e9e9e;
  font-size: 18px;
  font-weight: 400px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in;
}

.nav-middle ul li:hover {
  color: var(--primarty-color);
}

.nav-middle ul li::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 1%;
  background-color: var(--primarty-color);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  transition: all 0.3s ease-in;
  visibility: hidden;
  opacity: 0;
}

.nav-middle ul li.click-wallet::after {
  visibility: visible;
  opacity: 1;
  width: 150%;
}

.nav-middle ul li:hover::after {
  visibility: visible;
  opacity: 1;
  width: 150%;
}

/*---------------------------------------------------------------------------- 
    Sidebar
----------------------------------------------------------------------------*/
.sidebar {
  padding-top: 0;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #ffffff;
  overflow-y: auto;
  height: 100%;
  max-height: 850px;
}

.action-area {
  padding: 30px 0;
}

.action-area span {
  color: #9fa5ad;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
}

.action-area img {
  width: 15px;
  cursor: pointer;
  margin-left: 10px;
}

.action-area svg {
  color: #727272;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.action-area svg:last-child {
  margin-left: 10px;
}

.sidebar .accordion-button {
  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
  font-weight: 500;
  font-size: 18px;
}

.sidebar .accordion-item {
  border: 0;
}

.sidebar .accordion-button:not(.collapsed) {
  background-color: transparent;
}

.sidebar ul li {
  color: #2c2c2c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 20px 10px;
  margin: 10px 0;
  transition: all 0.3s ease-in;
}

.sidebar ul li:hover {
  background-color: #eaefff;
  cursor: pointer;
}

.fieldItem {
  padding: 40px;
  border: 1px solid #fafafa;
}

.activeItem {
  background-color: #eaefff;
  cursor: pointer;
}

.sidebar .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.sidebar ul li img {
  width: 15px;
  vertical-align: top;
  margin-right: 10px;
}

.nav-left .single-icon {
  margin-right: 25px;
  cursor: pointer;
}

.nav-left .single-icon:last-child {
  margin-right: 0;
}

.form-area p {
  color: #9ea5ad;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.form-area label {
  color: #454c52;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 15px;
}

.form-select {
  padding: 10px;
}

.form-area input .form-area .form-select {
  box-shadow: none !important;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e5e6e7;
}

.form-area input:focus,
.form-area .form-select:focus {
  box-shadow: none;
  border: 1px solid #e5e6e7;
}

.form-area input::placeholder,
.form-area .form-select,
.wallet-input textarea::placeholder {
  color: #9ea5ad;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}

.input-bottom {
  display: block;
  color: #676e76;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  margin-top: 10px;
}

.label-text label {
  margin-bottom: 10px;
}

.label-text .input-bottom {
  margin-top: 0;
}

.hr {
  height: 1px;
  width: 100%;
  background-color: #eaeaea;
}

.suggestion-box {
  padding: 15px;
  background-color: #f6fef9;
  border: 2px solid #bae1cd;
  border-radius: 15px;
}

.suggestion-box svg {
  color: #2f9461;
  font-size: 14px;
  line-height: 1;
  vertical-align: middle;
}

.suggestion-box p {
  color: #2f9461;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0;
}

.suggestion-box h5 {
  color: #2f9461;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.caution-box {
  padding: 15px;
  background-color: #fffcf5;
  border: 2px solid #f6d9b0;
  border-radius: 15px;
  margin-bottom: 15px;
}

.caution-box svg {
  color: #c8811a;
  font-size: 14px;
  line-height: 1;
  vertical-align: middle;
}

.caution-box .left {
  margin-right: 10px;
}

.caution-box .left svg {
  vertical-align: top;
}

.caution-box .top span {
  color: #c8811a;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}

.caution-box .top svg {
  cursor: pointer;
}

.caution-box .top {
  margin-bottom: 10px;
}

.caution-box .bottom p {
  color: #c8811a;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

/* sidebar theme */
.sidebar-theme h5 {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.sidebar-theme p {
  color: #727272;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 15px;
}

.logo-box img {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
}

.logo-box img.sidebar-logo {
  margin-bottom: 0;
}

.logo-box input,
.color-box,
.hero-image-box {
  margin-bottom: 30px;
}

.color-box input {
  width: 100%;
  margin-right: 15px;
}

.color-box label {
  margin-bottom: 0;
  cursor: pointer;
}

.hero-image-box img {
  height: 80px;
  margin-bottom: 10px;
  object-fit: cover;
}
.hero-image-box-generic img {
  height: 120px;
  width: 120px;
  margin-bottom: 10px;
  object-fit: cover;
}

.sidebar-theme button {
  border: 1px solid #e5e6e7;
  outline: 0;
  border-radius: 10px;
  width: 100%;
  background-color: #ffffff;
  padding: 15px;
}

.sidebar-theme button img {
  width: 15px;
  margin-right: 10px;
}

/* Barcode */
.form-switch label {
  color: #727272;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.barcodes {
  margin-top: 15px;
}

.barcode-single {
  padding: 30px 30px;
  border-radius: 15px;
  border: 1px solid #e5e5e5;
  text-align: center;
  margin-bottom: 20px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.barcode-single.active {
  border-color: rgba(41, 97, 252, 0.3);
  background-color: rgba(41, 97, 252, 0.1);
  background-color: rgba(41, 97, 252, 0.1);
}

.barcode-single:hover {
  border-color: rgba(41, 97, 252, 0.3);
  background-color: rgba(41, 97, 252, 0.1);
}

.barcode-square {
  height: 60px;
  width: 60px;
}

.barcode-single span {
  display: block;
  color: #2963ff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  margin-top: 15px;
}

.sidebar-theme h5 {
  color: #454c52;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.sidebar-theme input[type="text"] {
  width: 100%;
  border: 1px solid #e5e6e7;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 25px;
}

.payload-items {
  width: 100%;
  height: 150px;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  padding: 15px;
  position: relative;
  margin-bottom: 25px;
}

.payload-items span {
  display: inline-block;
  padding: 5px 10px;
  font-size: 14px;
  background-color: rgba(41, 98, 255, 0.1);
  border-radius: 15px;
  line-height: 1;
  color: var(--primarty-color);
  text-transform: capitalize;
  margin-right: 10px;
  margin-bottom: 10px;
}

.payload-items span svg {
  font-size: 11px;
}

.payload-items button {
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 100px;
  padding: 5px;
}

.checkboxes label {
  text-transform: capitalize;
}

.alert-box {
  margin-top: 25px;
  padding: 15px;
  border: 1px solid #dc3545;
  border-radius: 15px;
  background-color: rgba(220, 56, 72, 0.1);
}

.alert-box .content {
  margin-left: 10px;
}

.alert-box svg {
  color: #dc3545;
}

.alert-box .content p {
  color: #dc3545;
  font-size: 14px;
  font-weight: 500;
  margin-top: -5px;
  margin-bottom: 5px;
  line-height: 22px;
}

.alert-box .content ol li {
  color: #dc3545;
  font-size: 14px;
  font-weight: 500;
}

/* Location */
.sidebar-location button {
  text-align: right;
  border: 0;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  background-color: var(--primarty-color);
  padding: 10px 15px;
  line-height: 1;
  text-transform: capitalize;
  display: block;
  margin-left: auto;
}

.location-form {
  padding: 15px;
  border-radius: 15px;
  background-color: #eaeaea;
  margin-top: 30px;
}

.location-form.information p {
  color: #9e9e9e;
  font-size: 18px;
}

.information-input h5 {
  color: #2c2c2c;
}

.location-form p {
  color: #454c52;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 25px;
}

.location-form h5 {
  color: #454c52;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.location-form input {
  width: 100%;
  border: 1px solid #d4d5d6;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.notification {
  width: 20px;
  margin-right: 10px;
}

.location-form span {
  color: #676e76;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

/* Password */
.sidebar-password h5 {
  color: #454c52;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.sidebar-password input[type="password"] {
  width: 100%;
  border: 1px solid #d4d5d6;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.sidebar-password button {
  border: 0;
  width: 100%;
  background-color: var(--primarty-color);
  padding: 10px;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  border-radius: 5px;
  font-size: 15px;
  transition: all 0.3s ease-in;
}

.sidebar-password button:hover {
  background-color: rgba(43, 99, 253, 0.8);
}

/*---------------------------------------------------------------------------- 
    Wallet
----------------------------------------------------------------------------*/
.wallet-wrapper {
  padding-bottom: 100px;
}

.wallet-body span {
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.wallet-front {
  position: relative;
  width: 100%;
  background-color: #1e3181;
  border-radius: 15px;
  padding: 30px 30px 120px 30px;
}
.wallet-front-apple {
  position: relative;
  width: 100%;
  background-color: #1e3181;
  border-radius: 15px;
  padding: 30px;
}
.hero-google {
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 100%;
  height: 120px;
}
.hero-google-membership {
  position: absolute;
  bottom: -20px;
  left: 37%;
  width: 120px;
  height: 120px;
}
.hero-google img {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-google-membership img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wallet-header img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 15px;
}

.hero-area img {
  width: 100%;
  height: 85px;
  object-fit: cover;
}

.wallet-header.apple {
  text-align: center;
}

.wallet-header.apple img {
  width: 175px;
  height: auto;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
}

.data-collection .wallet-header {
  padding: 20px 0;
  background-color: #204dc6;
}

.data-collection .wallet-header img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.wallet-front span {
  margin: 0;
  color: #fafafa;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-transform: capitalize;
  text-align: left;
}

.wallet-front .hr {
  margin: 25px 0;
}

.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fafafa;
  display: inline-block;
  margin-right: 10px !important;
}

.wallet-form h4 {
  color: #fafafa;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  text-align: left;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.wallet-form-wrapper .wallet-form {
  flex-basis: 48%;
}

.field {
  padding: 15px;
  border: 1px dashed #fafafa;
  margin-bottom: 10px;
  height: 65px;
}

.field.bg {
  padding: 15px;
  border: 1px dashed #fafafa;
  margin-bottom: 10px;
  height: 60px;
  background-color: #c8c8c8;
}

.fieldItem {
  padding: 10px;
  border: 1px solid #fafafa;
}

.activeField {
  border: 2px solid #fafafa;
  font-weight: 700 !important;
}

.barcode {
  border: 10px solid transparent;
  border-radius: 15px;
  margin: 15px 0px;
  animation: barcode-animate 0.8s ease-in infinite;
  text-align: center;
  position: relative;
}

/* @keyframes barcode-animate {
  25% {
    border-color: #c5e45f;
  }
  50% {
    border-color: #94f269;
  }
  75% {
    border-color: #5ef296;
  }
  100% {
    border-color: #5ef1af;
  }
} */

.barcode img {
  width: auto;
  max-width: 375px;
  border: 10px solid #ffffff;
  border-radius: 5px;
  height: 130px;
  object-fit: cover;
  display: inline-block;
  margin: 0 auto;
  word-break: break-all;
}
.barcode img.qr {
  height: 135px;
  border-width: 5px;
}
.barcode img.Aztec {
  height: 90px;
  max-width: 375px;
  border-width: 5px;
}

.barcode span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #02040a;
  bottom: 0;
  word-break: break-all;
}

.wallet-details {
  padding: 30px;
  border-radius: 15px;
  background-color: #ffffff;
  text-align: left;
}

.wallet-details-appleWallet {
  padding: 30px 0px;
  border-radius: 15px;
  background-color: #ffffff;
  text-align: left;
  min-height: 200px;
}

.wallet-details h4 {
  color: #9e9e9e;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

.wallet-input {
  margin-bottom: 20px;
  cursor: grab;
}

.wallet-input label {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: capitalize;
  display: block;
  cursor: grab;
}

.wallet-input input,
.wallet-input textarea {
  display: block;
  width: 100%;
  border-radius: 10px;
  padding: 14px;
  background-color: #f6f7f9;
  border: 1px solid #e6e7e8;
  cursor: grab;
}

.wallet-input textarea:focus {
  border: 1px solid #e6e7e8;
  background-color: #f6f7f9;
}

.share-btn {
  width: 20px;
  cursor: pointer;
}

.gear-btn {
  width: 45px;
}

span.title {
  display: block;
  color: #9e9e9e;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 20px;
}

.form-btn {
  width: 100%;
  padding: 14px;
  border: 1px solid var(--primarty-color);
  background-color: var(--primarty-color);
  border-radius: 10px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.3s ease-in;
}

.form-btn:hover {
  background-color: #ffffff;
  color: var(--primarty-color);
}

.wallet-details .wallet-input {
  padding: 0 30px;
}

.wallet-details-appleWallet .wallet-input {
  padding: 0px;
}

.title {
  color: #9e9e9e;
  font-size: 18px;
  font-weight: 600;
}

button.add {
  color: #323232;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 15px 0;
  text-align: center;
  width: 100%;
  border: 1px solid #e5e6e7;
  border-radius: 10px;
  background-color: #ffffff;
  transition: all 0.3s ease-in;
}

.checkbox-input label {
  color: #727272;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
}

.checkbox-input {
  padding: 15px 30px;
  background-color: #eaeaea;
  border-radius: 15px;
  margin-bottom: 10px;
}

/*---------------------------------------------------------------------------- 
    Sign in / Sign out / Forgot password page
----------------------------------------------------------------------------*/
.signin-signout-page .col-lg-8 {
  background-color: #e1e1e1;
  height: 100vh;
}

.signin-signout-page-left {
  text-align: center;
  width: 100%;
}

.signin-signout-page-left img {
  width: 585px;
  margin-top: 160px;
}

.signin-signout-page .col-lg-4 {
  background-color: #f9f9f9;
  padding: 100px;
  height: 100vh;
}

.signin-signout-page-right h2 {
  color: #121212;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 15px;
}

.signin-signout-page-right p {
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.signin-signout-page-right p strong {
  color: #121212;
}

.signin-signout-page .main-form {
  margin-top: 30px;
}

.signin-signout-page .main-form label {
  color: #454c52;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 10px;
}

.signin-signout-page .main-form input {
  border: 1px solid #e1e3e4;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff !important;
}

.signin-signout-page .main-form input::placeholder {
  color: #9fa5ad;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}

.signin-signout-page .main-form input:focus,
.wallet-input textarea:focus {
  box-shadow: none;
}

.signin-signout-page .main-form button {
  width: 100%;
  padding: 15px 0;
  color: #fff;
  font-weight: 500;
  line-height: 1;
  background-color: #3c60fc;
  transition: all 0.3s ease-in;
}

.signin-signout-page .main-form button:hover {
  background-color: rgba(61, 96, 252, 0.1);
  color: #02040a;
  border-color: rgba(61, 96, 252, 0.3);
}

.signin-signout-page-right span {
  position: relative;
  display: block;
  color: #565656;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  margin-bottom: 30px;
}

.signin-signout-page-right span::before {
  position: absolute;
  content: "";
  width: 30%;
  height: 1px;
  background-color: #e8e8e8;
  top: 52%;
  left: 0;
}

.signin-signout-page-right span::after {
  position: absolute;
  content: "";
  width: 30%;
  height: 1px;
  background-color: #e8e8e8;
  top: 52%;
  right: 0;
}

.signin-signout-page-right button {
  border: 1px solid #e1e3e4;
  border-radius: 10px;
  padding: 10px 0;
  text-align: center;
  background-color: #ffffff;
  color: #121212;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  flex-basis: 49%;
}

.signin-signout-page-right button img {
  width: 25px;
  margin-right: 10px;
}

.signin-signout-page-right .keos-logo {
  display: block;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.signin-page .signin-signout-page-right p {
  color: #121212;
}

.signin-page .signin-signout-page-right button {
  flex-basis: 100%;
  margin-bottom: 15px;
}

.signin-page .signin-signout-page-right button:last-child {
  margin-bottom: 0;
}

.signin-signout-page-right a {
  font-weight: 500;
  margin-bottom: 5px;
}

/* Modal Design */
.modal-heading h2 {
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-top: 10px;
}

.modal-heading p {
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-top: 10px;
}

.settings-tab {
  margin: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.settings-tab li {
  color: #9e9e9e;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  position: relative;
  padding: 20px 40px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.settings-tab li::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 10%;
  background-color: var(--primarty-color);
  transition: all 0.3s ease-in;
  border-radius: 5px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
}

.settings-tab li:hover::after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.settings-tab li.tab-active::after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.settings-tab {
  margin-top: 0;
  padding-top: 0;
}

.buttons button {
  border: 1px solid #e6e7e8;
  border-radius: 10px;
  padding: 15px 0;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.3s ease-in;
  flex-basis: 49%;
}

.buttons button:last-child {
  background-color: var(--primarty-color);
  color: #ffffff;
}

.custom-c {
  display: block;
  width: 100%;
  height: 100vh;
  position: relative;
}

.custom-c p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Modal Design */
.modal-heading h2 {
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-top: 10px;
}

.modal-heading p {
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-top: 10px;
}

.settings-tab {
  margin: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.settings-tab li {
  color: #9e9e9e;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  position: relative;
  padding: 20px 40px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.settings-tab li::after {
  position: absolute;
  content: "";
  height: 5px;
  width: 10%;
  background-color: var(--primarty-color);
  transition: all 0.3s ease-in;
  border-radius: 5px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
}

.settings-tab li:hover::after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.settings-tab {
  margin-top: 0;
  padding-top: 0;
}

.buttons button {
  border: 1px solid #e6e7e8;
  border-radius: 10px;
  padding: 15px 0;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  transition: all 0.3s ease-in;
  flex-basis: 49%;
}

.buttons button:last-child {
  background-color: var(--primarty-color);
  color: #ffffff;
}

/* Create Section */
.container.custom a {
  display: block;
}

.create-button {
  border-radius: 30px;
  border: 1px solid #dadbdc;
  text-align: center;
  padding: 60px 0;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.1s ease-in;
  margin-bottom: 25px;
}

.create-button:hover,
.draft-box:hover {
  box-shadow: 0 2px 3px 0 var(--primarty-color);
}

.draft-box {
  height: 225px;
  padding-top: 60px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #dadbdc;
  border-radius: 30px;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.1s ease-in;
  margin-bottom: 25px;
}

.container.custom {
  max-width: 1140px;
}

.plus {
  font-size: 50px;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--primarty-color);
  display: inline-block;
  line-height: 45px;
  color: #ffffff;
  margin-bottom: 0;
  margin-bottom: 20px;
}

.text {
  color: #02040a;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.draft-box ul li,
.draft-box span {
  color: #727272;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  margin-right: 5px;
}

.draft-box span {
  font-size: 14px;
}

.draft-box h3 {
  line-height: 1;
  color: var(--primarty-color);
  font-size: 24px;
  text-transform: capitalize;
  margin-bottom: 15px;
  margin-top: 20px;
}

span.s-dots {
  color: #727272;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  cursor: pointer;
  display: block;
  float: right;
  margin-right: 20px;
}

.dots ul {
  width: 100px;
  text-align: right;
  top: -90px;
  right: 0;
  background-color: #ebeffe;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in;
  box-shadow: 0 2px 3px 0 #ddd;
}

.dots ul.active {
  opacity: 1;
  visibility: visible;
}

.dots ul li a {
  color: #02040a;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 5px 0;
  transition: all 0.3s ease-in;
}
.dots ul li {
  padding: 5px 0;
  border-bottom: 1px solid #dadbdc;
}
.dots ul li:last-child {
  border-bottom: 0;
}
.dots ul li:hover a {
  color: #204dc6;
}
.section-heading {
  position: relative;
  display: inline-block;
  line-height: 1;
}
.section-heading::before {
  position: absolute;
  content: ">>>>>>>>>>>>>>>>>>>>";
  top: -15px;
  left: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: cursive;
  color: #204dc6;
  line-height: 1;
}
.section-heading::after {
  position: absolute;
  content: "<<<<<<<<<<<<<<<<<<<<";
  bottom: -16px;
  right: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: cursive;
  color: #204dc6;
  line-height: 1;
}
/* DROPDOWN */
#dropdown-basic {
  padding: 0;
  border: 0;
  background-color: transparent;
  margin-bottom: 0;
  line-height: 0;
}
.dropdown-menu.show {
  max-height: 270px;
  width: 500px;
  overflow-y: scroll;
  background-color: #f1f1f1;
}

.dd-list:hover {
  background-color: #1d1d1d;
}
.dd-list:hover p {
  color: #ffffff;
}
.dd-header p,
.dd-list p {
  word-break: break-all;
  overflow: hidden;
  white-space: normal;
  margin-bottom: 0;
  color: #000000;
  font-size: 13px;
}
.dd-list p svg {
  width: 12px;
  margin-right: 2px;
  line-height: 1;
  vertical-align: bottom;
}
.dd-list {
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 3px 5px 0 #7f8c8d;
  background-color: #ffffff;
  border-radius: 5px;
}
.dd-list:last-child {
  border-bottom: 0;
}
#dropdown-basic a:hover {
  background-color: red;
}
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item.active:hover,
.dropdown-item:hover {
  background-color: #f1f1f1;
}

/* Apple Wallet Membership Card */
.awmc-img {
  flex-basis: 30%;
}
.primary-field.field {
  margin-bottom: 0;
  height: 85px;
  min-width: 125px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  word-break: break-word;
}
.primary-field span {
  display: block;
  line-height: 1;
}
.form-check-input:checked {
  background-color: #52d869;
  border-color: #52d869;
}
.form-switch .form-check-input {
  width: 35px;
  height: 25px;
}
.form-check-input:focus {
  box-shadow: none;
}

.reactEasyCrop_Contain {
  z-index: 9;
  border: 1px solid red;
  object-fit: cover;
}

.reactEasyCrop_CropArea {
  z-index: 99;
}
.reactEasyCrop_Container {
  border: 1px solid red;
  width: 610px;
  height: 328px;
  top: 50% !important;
  left: 50% !important;
  overflow: hidden;
  transform: translate(-50%, -50%) !important;
  z-index: 99;
}
.error-screen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.divider{
  position: relative;
  overflow: hidden;
}
.divider::after{
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  background-color: #dadbdc;
  top: 4%;
  left: 50%;
}

.save-btn .spinner-border{
  width: 19px;
  height: 19px;
}

.spinner-border.draft-page{
  position: absolute;
  top: 50%;
  left: 50%;
}

.pass-view{
  position: absolute;
  right: 10px;
  top: 50%;
  cursor: pointer;
}

