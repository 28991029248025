@media screen and (max-width: 1600px) {
  .barcode img.qr {
    max-width: 275px;
    border-width: 5px;
  }
  .barcode img.Aztec {
    height: 75px;
    max-width: 255px;
    border-width: 5px;
  }
  .hero-google-membership {
    position: absolute;
    bottom: -20px;
    left: 32%;
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 1399px) {
  /* SIGN IN PAGE */
  .signin-signout-page .col-lg-4 {
    padding: 30px;
  }
}
@media screen and (max-width: 991px) {
  /* NAVBAR */
  .main-nav .row {
    justify-content: space-between;
  }
  .main-nav .single-icon {
    margin-left: 0;
  }
  .signin-signout-page-left img {
    margin-top: 30px;
  }
  /* SIGN IN PAGE */
  .signin-signout-page .col-lg-8,
  .signin-signout-page .col-lg-4 {
    height: auto;
  }
  .signin-signout-page-left img {
    margin-top: 0;
    padding: 30px 0;
  }
}
@media screen and (max-width: 767px) {
  /* HEADER BOTTOM */
  .header-bottom-right button {
    font-size: 15px;
    padding: 10px 30px;
    margin-left: 10px;
    border-radius: 7px;
  }
  .single-icon {
    height: 30px;
    line-height: 25px;
    width: 30px;
  }
  .single-icon img {
    width: 15px;
  }
  .search-form input {
    width: 200px;
  }
  .search-form input::placeholder {
    font-size: 14px;
  }
  /* NAVBAR */
  .nav-middle ul li {
    font-size: 15px;
  }
  .nav-middle ul li::after {
    height: 3px;
  }
  .nav-left .single-icon {
    margin-right: 5px;
  }
  .main-nav {
    height: 40px;
    line-height: 40px;
  }
}
@media screen and (max-width: 575px) {
  /* HEADER TOP */
  .icons .icon-single {
    height: 30px;
    width: 30px;
    line-height: 30px;
  }
  .icons img,
  .user-info img {
    height: 30px;
    width: 30px;
  }
  .info p {
    font-size: 16px;
  }
  .info span {
    font-size: 14px;
  }
  .info img {
    height: 5px;
    width: 10px;
  }
  /* HEADER BOTTOM */
  .search-form input {
    width: 115px;
  }
  /* NAVBAR */
  .main-nav .row {
    flex-wrap: nowrap;
  }
  .nav-middle ul li {
    font-size: 12px;
  }
  .main-nav .col-3 {
    width: 35%;
  }
  /* SIGN IN PAGE */
  .signin-signout-page-left img {
    width: 300px;
  }
}
@media screen and (max-width: 400px) {
  /* HEADER TOP */
  .info p {
    font-size: 14px;
  }
  .header-top .icons .icon-single {
    display: none;
  }
  .top-right.buttons a p {
    font-size: 14px;
    padding: 10px 15px;
  }
  /* HEADER BOTTOM */
  .search-form input {
    width: 115px;
  }
  .header-bottom-right button {
    font-size: 14px;
    padding: 5px 15px;
    margin-left: 5px;
  }
  .single-icon {
    height: 20px;
    line-height: 15px;
    width: 20px;
    margin-left: 5px;
  }
  .single-icon img {
    width: 10px;
  }
  .search-form input {
    width: 110px;
    height: 31px;
    border-radius: 7px;
    padding: 5px;
  }
  .search-form input::placeholder {
    font-size: 12px;
    line-height: 12px;
  }
  /* NAVBAR */
  .nav-middle ul li {
    font-size: 12px;
    line-height: 16px;
  }
  .main-nav {
    border-bottom: 1px solid;
  }
  .nav-middle ul li::after {
    bottom: -7px;
  }
  .main-nav .nav-left .single-icon {
    margin-left: 0;
    margin-right: 10px;
  }
  /* SIGN IN PAGE */
  .signin-signout-page-left img {
    width: 150px;
  }
  .signin-signout-page-right h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  .signin-signout-page-right p {
    font-size: 13px;
    line-height: 1;
    margin-bottom: 10px;
  }
  .signin-signout-page-right h3 {
    font-size: 16px;
    margin-top: 10px;
  }
  .signin-signout-page .main-form {
    margin-top: 25px;
  }
  .signin-signout-page .main-form label {
    font-size: 14px;
  }
  .signin-signout-page .main-form input {
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
  }
  .main-form .mb-4 {
    margin-bottom: 15px !important;
  }
  .signin-signout-page-right a {
    font-size: 14px;
  }
  .signin-signout-page .main-form button {
    padding: 10px 0;
    border-radius: 5px;
  }
  .signin-page .signin-signout-page-right p {
    margin-bottom: 0 !important;
  }
  .signin-signout-page-right button {
    font-size: 14px;
  }
  .signin-signout-page-right .keos-logo {
    margin-top: 30px;
  }
  .signin-signout-page-right .keos-logo {
    width: 100px;
  }
}
